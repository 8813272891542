import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <article className="c-content">
      <div className="container">
        <div className="row">
          <div className="c-content__container">
            <h1>Page Not Found</h1>
            <p>Unfortunately the page you tried to access was unavailable.</p>
            <p>You may have typed the web address incorrectly.
              Please check the address and spelling ensuring that it does not
              contain capital letters or spaces.</p>
            <p>It is possible that the page you were looking for may have
              been moved, updated or deleted.</p>
          </div>
        </div>
      </div>
    </article>
  </Layout>
)

export default NotFoundPage
